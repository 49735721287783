var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "photograph",
      attrs: { title: "人脸采集", "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      !_vm.option.img
        ? _c("video", {
            attrs: { id: "video", width: "480px", height: "360px" },
          })
        : _vm._e(),
      _vm.option.img
        ? _c(
            "div",
            {
              staticStyle: {
                height: "360px",
                width: "480px",
                "text-align": "center",
              },
            },
            [
              _c("VueCropper", {
                ref: "cropper",
                attrs: {
                  img: _vm.option.img,
                  outputSize: _vm.option.size,
                  outputType: _vm.option.outputType,
                  info: true,
                  autoCropWidth: _vm.option.autoCropWidth,
                  autoCropHeight: _vm.option.autoCropHeight,
                  full: _vm.option.full,
                  canMove: _vm.option.canMove,
                  canMoveBox: _vm.option.canMoveBox,
                  original: _vm.option.original,
                  autoCrop: _vm.option.autoCrop,
                  fixed: _vm.option.fixed,
                  fixedNumber: _vm.option.fixedNumber,
                  centerBox: _vm.option.centerBox,
                  infoTrue: _vm.option.infoTrue,
                  fixedBox: _vm.option.fixedBox,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("canvas", {
        staticStyle: { display: "none" },
        attrs: { id: "canvasCamera", width: "500", height: "500" },
      }),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("input", {
            staticStyle: { width: "40%" },
            attrs: { type: "file", id: "upImageFile" },
            domProps: { value: _vm.fileValue },
            on: { change: _vm.chooseImage },
          }),
          _c(
            "Button",
            { attrs: { type: "info" }, on: { click: _vm.changeDevice } },
            [_vm._v("切换")]
          ),
          _c(
            "Button",
            { attrs: { type: "warning" }, on: { click: _vm.rephotograph } },
            [_vm._v("重拍")]
          ),
          _c(
            "Button",
            { attrs: { type: "primary" }, on: { click: _vm.setImage } },
            [_vm._v("拍照")]
          ),
          _c(
            "Button",
            { attrs: { type: "success" }, on: { click: _vm.confirmSubmit } },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }